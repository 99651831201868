













































import {
  addCollectionSetting,
  getCollectionSetting,
  isUniqueName,
  isUniqueURL,
  updateCollectionSetting,
} from '@/api/collection-setting';
import { ElForm } from 'element-ui/types/form';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'CollectionSettingForm',
})
export default class extends Vue {
  @Prop({ default: '' }) private uid!: string;

  mounted() {
    if (this.uid) this.setForm();
    else this.loading = false;
  }

  private loading = true;

  private formData = {
    name: '',
    subjectTag: '',
    contentTag: '',
    writerTag: '',
    dateTag: '',
    homepage: '',
    enabled: true,
    encodeType: 'UTF-8',
  };

  private checkDuplicateName = (rule: any, value: string, callback: Function) => {
    if (!this.uid) {
      isUniqueName(value).then((res) => {
        if (res.data) callback();
        else callback(new Error('중복된 미디어명입니다.'));
      });
    } else {
      callback();
    }
  }

  private checkDuplicateURL = (rule: any, value: string, callback: Function) => {
    if (!this.uid) {
      isUniqueURL(value).then((res) => {
        if (res.data) callback();
        else callback(new Error('중복된 홈페이지입니다.'));
      });
    } else {
      callback();
    }
  }

  private rules = {
    name: [
      { required: true, message: '미디어명을 입력해주세요.', trigger: 'blur' },
      { validator: this.checkDuplicateName, trigger: 'blur' },
    ],
    subjectTag: [
      { required: true, message: '제목 태그를 입력해주세요.', trigger: 'blur' },
    ],
    contentTag: [
      { required: true, message: '내용 태그를 입력해주세요.', trigger: 'blur' },
    ],
    homepage: [
      { required: true, message: '홈페이지URL을 입력해주세요.', trigger: 'blur' },
      // { type: 'url', message: '올바른 URL형식으로 입력해주세요.', trigger: 'blur' },
      { validator: this.checkDuplicateURL, trigger: 'blur' },
    ],
    encodeType: [
      { required: true, message: '인코딩 방식을 선택해주세요.', trigger: 'blur' },
    ],
  }

  private setForm() {
    getCollectionSetting(this.uid).then((res) => {
      this.loading = false;
      this.formData = res.data;
    });
  }

  private handleCancle() {
    this.$emit('close');
  }

  private handleSubmit() {
    (this.$refs.collectionForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        if (this.uid) {
          updateCollectionSetting(this.uid, this.formData).then(() => {
            this.$message.success('성공적으로 크롤링 대상을 수정했습니다.');
            this.$emit('save');
          }).catch((error) => {
            this.$message.error(error.response.data);
          });
        } else {
          addCollectionSetting(this.formData).then(() => {
            this.$message.success('성공적으로 크롤링 대상을 추가했습니다.');
            this.$emit('save');
          });
        }
      }
    });
  }
}
