













































































import { Component, Vue } from 'vue-property-decorator';
import { deleteCollectionSetting, getCollectionSettingList } from '@/api/collection-setting';
import SettingForm from './components/SettingForm.vue';
import Pagination from '@/components/Pagination/index.vue';

@Component({
  name: 'CollectionSetting',
  components: {
    Pagination,
    SettingForm,
  },
})
export default class extends Vue {
  mounted() {
    this.getCollectionSettingList();
  }

  private uid = '';

  private loading = true;

  private formVisible = false;

  private listQuery = {
    page: 1,
    size: 10,
    type: 'name',
    value: '',
  };

  private totalElements = 0;

  private dataList = [];

  private getCollectionSettingList() {
    this.loading = true;
    getCollectionSettingList(this.listQuery).then((res) => {
      if (res.data.content.length < 1 && this.listQuery.page > 1) {
        this.listQuery = {
          ...this.listQuery,
          page: this.listQuery.page - 1,
        };
        this.getCollectionSettingList();
      } else {
        this.dataList = res.data.content;
        this.totalElements = res.data.totalElements;
        this.loading = false;
      }
    });
  }

  private handleChangePage(pageable: any) {
    this.listQuery.page = pageable.page;
    this.getCollectionSettingList();
  }

  private handleSearch() {
    this.listQuery.page = 1;
    this.getCollectionSettingList();
  }

  private handleVisibleForm(uid: string) {
    this.uid = uid;
    this.formVisible = !this.formVisible;
  }

  private handleDelete(item: any) {
    this.$confirm(`정말 ${item.name}을(를) 삭제하시겠습니까?`, 'Warning', {
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      deleteCollectionSetting(item.uid).then(() => {
        this.$message.success('성공적으로 크롤링 대상을 삭제했습니다.');
        this.getCollectionSettingList();
      }).catch(() => {
        this.$message.error('크롤링 대상 삭제를 실패했습니다.');
      });
    });
  }

  private handleSuccessSave() {
    this.formVisible = !this.formVisible;
    this.getCollectionSettingList();
  }
}
