import request from '@/util/request';

const PATH = '/collection-setting';

export const getCollectionSettingList = (listQuery: any) => request({
  url: `${PATH}`,
  method: 'get',
  params: {
    ...listQuery,
    page: listQuery.page - 1,
  },
});

export const getCollectionSetting = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});

export const addCollectionSetting = (data: any) => request({
  url: `${PATH}`,
  method: 'post',
  data,
});

export const updateCollectionSetting = (uid: string, data: any) => request({
  url: `${PATH}/${uid}`,
  method: 'put',
  data,
});

export const deleteCollectionSetting = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'delete',
});

export const isUniqueName = (param: string) => request({
  url: `${PATH}/check-name`,
  method: 'get',
  params: {
    name: param,
  },
});

export const isUniqueURL = (param: string) => request({
  url: `${PATH}/check-url`,
  method: 'get',
  params: {
    url: param,
  },
});
